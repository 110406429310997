import { LoadingButton } from '@mui/lab';
// import { Auth } from 'aws-amplify';
import { fetchAuthSession, signIn, signOut } from 'aws-amplify/auth';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ICONS } from '../../../../../constants/Icons';
import { ROUTE } from '../../../../../constants/Routes';
import {
  handleSmsSent,
  handleToken,
  handleUserLoggedIn,
} from '../../../../../redux/Reducer/Auth';
import { getUsersData } from '../../../../../redux/Reducer/Profile';
import { toggleSnackBar } from '../../../../../redux/Reducer/Utils';
import { sendOtp, verifyOtp } from '../../../../../services/Auth';
import TurnstileWidget from '../../../../../utils/Turnstile/TurnstileWidget';
import { LoginValidationSchema } from '../../../../../utils/ValidationSchema';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  SnackBar,
  TextField,
  Typography,
} from '../../../../Containers/index';

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isVerified, reCapchaToken, isUserLoggedIn } = useSelector(
    (state) => state?.auth
  );
  const [showRecapcha, setShowRecapcha] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [is2Fa, setIs2Fa] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [idToken, setIdToken] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [otp, setOtp] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  // const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(60);
  const { userData } = useSelector((state) => state.user);
  const { isSmsSent } = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginValidationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      await signOut();
      try {
        // const user = await Auth.signIn(
        //   values?.email?.toLowerCase(),
        //   values.password
        // );

        const user = await signIn({
          username: values?.email,
          password: values.password,
        });

        console.log(user);
        // const { accessToken, idToken } =
        //   (await fetchAuthSession()).tokens ?? {};
        const session = await fetchAuthSession();
        const { accessToken, idToken } = session?.tokens;
        console.log(session?.tokens);
        console.log(session);
        setAccessToken(accessToken?.toString());
        setIdToken(idToken?.toString());
        localStorage.setItem('idToken', idToken?.toString());
        console.log('kkkk');
        dispatch(getUsersData());
        setTimeout(() => {
          localStorage.removeItem('idToken');
        }, 500);
      } catch (error) {
        let errorMessage;
        console.log(error);
        switch (error.name) {
          case 'UserNotFoundException':
            errorMessage = 'User not found. Check email/username.';
            break;
          case 'NotAuthorizedException':
            errorMessage = 'Incorrect username or password. Try again.';
            break;
          case 'PasswordResetRequiredException':
            errorMessage = 'Password reset required. Check email.';
            break;
          case 'UserNotConfirmedException':
            errorMessage = 'User not confirmed. Verify email.';
            break;
          case 'CodeMismatchException':
            errorMessage = 'Invalid confirmation code. Retry.';
            break;
          case 'ExpiredCodeException':
            errorMessage = 'Confirmation code expired. Resend code.';
            break;
          case 'InvalidParameterException':
            errorMessage = 'Invalid input. Check credentials.';
            break;
          case 'InvalidPasswordException':
            errorMessage = 'Invalid password. Follow policy.';
            break;
          case 'TooManyFailedAttemptsException':
            errorMessage = 'Too many failed attempts. Wait.';
            break;
          case 'TooManyRequestsException':
            errorMessage = 'Request limit reached. Wait and retry.';
            break;
          case 'LimitExceededException':
            errorMessage = 'User pool full. Retry later.';
            break;
          default:
            errorMessage = error.message || 'Unknown error. Contact support.';
        }

        // return rejectWithValue(error.message);
        setIsLoading(false);

        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'error',
            message: errorMessage,
          })
        );
      } finally {
        // setIsLoading(false);
        setShowRecapcha(false);
      }
    },
  });

  const sendOtp2fa = async (token, isResend) => {
    try {
      if (!isResend) {
        setIsLoading(true);
      } else {
        setIsResend(true);
      }
      const result = await sendOtp({ token: token });
      setIs2Fa(true);
      if (isResend) {
        setSeconds(60);
      }
      // localStorage.setItem('isSMSSent', true);
      dispatch(handleSmsSent(true));

      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'success',
          message: result?.data?.message,
        })
      );
    } catch (e) {
      localStorage.clear();
      // console.log('error called');
      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'error',
          message: e,
        })
      );
    } finally {
      setIsLoading(false);
      setIsResend(false);
    }
  };
  const verifyOtp2fa = async (token) => {
    try {
      setIsLoading(true);

      const result = await verifyOtp({ token: token, otp: otp });

      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'success',
          message: result?.data?.message,
        })
      );
      // const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      localStorage.setItem('idToken', idToken);
      localStorage.setItem('accessToken', accessToken);
      // localStorage.removeItem('isSMSSent');
      dispatch(getUsersData());
      setTimeout(() => {
        if (
          userData?.actions_required &&
          userData?.actions_required[0]?.includes('RESET_PASSWORD')
        ) {
          dispatch(handleToken({ idToken: idToken, accessToken: accessToken }));
          localStorage.removeItem('idToken');
          localStorage.removeItem('accessToken');
          navigate(ROUTE.SET_PASSWORD);
        } else {
          dispatch(handleSmsSent(false));

          dispatch(handleUserLoggedIn(true));

          navigate(ROUTE.ROOT);
        }
        dispatch(
          toggleSnackBar({
            isOpen: false,
            type: 'success',
            message: result?.data?.message,
          })
        );
      }, 500);
    } catch (e) {
      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'error',
          message: e,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };
  const handleSignOut = async () => {
    await signOut();
  };
  useEffect(() => {
    if (userData?._id && isUserLoggedIn === false) {
      if (userData?.roles.join(',').includes('PARTNER')) {
        if (
          userData?.actions_required &&
          userData?.actions_required[0]?.includes('RESET_PASSWORD')
        ) {
          dispatch(handleToken({ idToken: idToken, accessToken: accessToken }));
          localStorage.removeItem('idToken');
          localStorage.removeItem('accessToken');
          navigate(ROUTE.SET_PASSWORD);
          return;
        }
        // else {
        //   dispatch(handleSmsSent(false));

        //   dispatch(handleUserLoggedIn(true));

        //   navigate(ROUTE.ROOT);
        // }
        if (isSmsSent === false) {
          sendOtp2fa(idToken, false);
        }
      } else {
        dispatch(handleUserLoggedIn(false));
        handleSignOut();
        localStorage.clear();
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'error',
            message: 'Access Denied',
          })
        );
        setTimeout(() => {
          window.location.replace('/');
          window.location.reload();
        }, 2000);
      }
    }
    // eslint-disable-next-line
  }, [userData]);
  const handleChange = (newValue) => {
    setOtp(newValue);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      // if (seconds === 0) {
      //   if (minutes === 0) {
      //     clearInterval(interval);
      //   } else {
      //     setSeconds(59);
      //     setMinutes(minutes - 1);
      //   }
      // }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  const resendOTP = () => {
    sendOtp2fa(idToken, true);
  };

  useEffect(() => {
    // if (is2Fa) {
    clearInterval(seconds);
    setSeconds(60);
    // }
  }, [is2Fa]);
  const handleCapcha = async (e) => {
    e?.preventDefault();

    const err = await formik?.validateForm();
    // console.log(err);
    if (Object.keys(err).length === 0) {
      setShowRecapcha(true);
    } else {
      Object.keys(err).forEach((k) => {
        formik.setFieldTouched(k, true);
      });
    }
  };
  useEffect(() => {
    if (reCapchaToken) {
      formik.handleSubmit();
    } else {
      setIsLoading(false);
    }
  }, [reCapchaToken]);
  useEffect(() => {
    setIsLoading(false);
    setTimeout(() => {
      setShowRecapcha(false);
    }, 5000);
  }, [isVerified]);
  return (
    <Box>
      <SnackBar />
      {!is2Fa ? (
        <>
          <Typography
            my={4}
            sx={{
              fontWeight: 900,
              fontSize: '24px',
              fontStyle: 'normal',
              // color: 'primary.main',
            }}
          >
            {t('Login_title')}
          </Typography>
          <TextField
            inputProps={{ 'data-testid': 'signin-email-or-name' }}
            fullWidth
            sx={{ my: 2 }}
            label='Email Address'
            name='email'
            error={formik.touched.email && Boolean(formik.errors.email)}
            value={formik.values.email
              .trimStart()
              .replace(/\s\s+/g, '')
              .replace(/\p{Emoji_Presentation}/gu, '')}
            onChange={(e) => {
              setErrorMessage('');
              formik.handleChange(e);
            }}
            helperText={
              formik.touched.email &&
              formik.errors.email &&
              t(formik.errors.email)
            }
          />

          <TextField
            inputProps={{ 'data-testid': 'signin-password' }}
            fullWidth
            sx={{ my: 2 }}
            type={showPassword ? 'text' : 'password'}
            label='Password'
            error={formik.touched.password && Boolean(formik.errors.password)}
            placeholder='Password'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    edge='end'
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    {showPassword ? (
                      <ICONS.VISIBILITY />
                    ) : (
                      <ICONS.VISIBILITY_OFF />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            name='password'
            value={formik.values.password
              .trimStart()
              .replace(/\s\s+/g, '')
              .replace(/\p{Emoji_Presentation}/gu, '')}
            onChange={(e) => {
              setErrorMessage('');
              formik.handleChange(e);
            }}
            helperText={
              formik.touched.password &&
              formik.errors.password &&
              t(formik.errors.password)
            }
          />

          {errorMessage !== '' && (
            <Typography mt={1} ml={1}>
              {Boolean(errorMessage) && (
                <Box sx={{ color: 'error.main' }}>{errorMessage}</Box>
              )}
            </Typography>
          )}
          <LoadingButton
            loading={isLoading}
            disabled={isLoading}
            fullWidth
            variant='contained'
            onClick={(e) => handleCapcha(e)}
            // onClick={() => setIs2Fa(true)}
            sx={{
              my: 2,
              textTransform: 'none',
              '& .MuiCircularProgress-root ': {
                color: '#ffff',
              },
            }}
          >
            Sign In
          </LoadingButton>
          {showRecapcha && (
            <Box sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
              <TurnstileWidget />
            </Box>
          )}
          {!isLoading && (
            <Grid
              container
              display='flex'
              justifyContent='space-between'
              mt={2}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  display='flex'
                  justifyContent='center'
                  color='text.secondary'
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(ROUTE.FORGET_PASSWORD);
                  }}
                >
                  Forgot password?
                </Box>
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <Box>
          <SnackBar />
          <Typography
            my={4}
            sx={{
              fontWeight: 900,
              fontSize: '24px',
              fontStyle: 'normal',
            }}
          >
            {t('Enter_Email_OTP')}
          </Typography>
          <Typography
            my={4}
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              fontStyle: 'normal',
            }}
          >
            {t('Otp_Sub_Title')}
          </Typography>
          <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
            <OtpInput
              separator={
                <Grid
                  sx={{
                    lineHeight: '24px',
                    fontWeight: 600,
                    fontSize: '16px',
                  }}
                >
                  <strong>-</strong>
                </Grid>
              }
              onChange={handleChange}
              value={otp}
              numInputs={6}
              inputStyle={{
                width: '100%',
                height: '40px',
                margin: '0px 8px',
                borderRadius: '4px',
                border: '1px solid rgba(0,0,0,0.3)',
              }}
            />
          </Grid>

          {errorMsg !== '' && (
            <Box>
              <Box
                sx={{
                  color: 'error.main',
                  fontSize: '0.75rem',
                  ml: 4,
                }}
              >
                {t(errorMsg)}
              </Box>
            </Box>
          )}
          <Grid
            container
            display='flex'
            justifyContent='space-between'
            mt={2}
            spacing={2}
          >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Button
                fullWidth
                variant='outlined'
                onClick={() => {
                  setIs2Fa(false);
                  setIsLoading(false);
                }}
                sx={{
                  my: 2,
                  textTransform: 'capitalize',
                }}
              >
                {t('Cancel')}
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <LoadingButton
                loading={isLoading}
                disabled={isLoading || otp.length !== 6}
                fullWidth
                variant='contained'
                onClick={() => verifyOtp2fa(idToken)}
                sx={{
                  my: 2,
                  textTransform: 'none',
                  '& .MuiCircularProgress-root ': {
                    color: '#ffff',
                  },
                }}
              >
                {t('Submit')}
              </LoadingButton>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              mt: 2,
              justifyContent: 'center',
            }}
          >
            <LoadingButton
              disabled={seconds > 0}
              loading={isResend}
              onClick={() => resendOTP()}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
                textTransform: 'capitalize',
              }}
            >
              {t('Resend')}
            </LoadingButton>
            <Typography
              sx={{
                color: 'grey',
                display: seconds > 0 ? 'flex' : 'none',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
                alignItems: 'center',
              }}
            >
              {'OTP in'} {seconds < 10 ? `0${seconds}` : seconds} s
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Login;
